import { useQuery, gql } from "@apollo/client";

import React, { useEffect, useState } from "react";
import css from "styled-jsx/css";
import Link from "next/link";
import stylingConfig from "../styling/config";
import UserCard from "../components/userCard";
import Row from "../components/row";
import Button from "../components/button";
import { ThemeContext } from "../lib/contexts";
import { HeaderSelfInfoQuery } from "../types/graphql";

export const GET_SELF = gql`
  query headerSelfInfo {
    getSelf {
      id
      firstName
      lastName
      hasProfilePicture
    }
  }
`;

const styles = css`
  .header {
    width: 100%;
    background-color: ${stylingConfig.colours.koda};
  }
  .wrapper {
    margin: 0 auto;
    height: 68px;
    display: flex;
    align-items: center;
    background-color: ${stylingConfig.colours.koda};
    justify-content: space-between;
  }
  .leftWrapper {
    display: flex;
    align-items: center;
    padding: 14px;
  }
  .logo,
  .logo img {
    height: 40px;
  }
  .profile {
    margin-left: 20px;
  }
  .phoneButton {
    margin-left: 30px;
    position: relative;
  }
  .phoneImg {
  }
`;

const Header = () => {
  // used to ensure access token is kept valid for the whole app (especially for the support sidebar)
  const getSelfQuery = useQuery<HeaderSelfInfoQuery>(GET_SELF, {
    pollInterval: 1200000
  });

  const [phoneDiallerOpen, togglePhoneDialler] = useState(false);

  return (
    <ThemeContext.Provider value="supportChat">
      <header className="header" id="waggelHeader">
        <div className="wrapper">
          <div className="leftWrapper">
            <Link href="/">
              <a className="logo">
                <img
                  alt="Waggel Pet Insurance"
                  src={`${process.env.staticUrl}/w.svg`}
                  height={40}
                  width={40}
                />
              </a>
            </Link>
            <div className="profile">
              {getSelfQuery.data && getSelfQuery.data.getSelf && (
                <UserCard user={getSelfQuery.data.getSelf} />
              )}
            </div>
            <div className="phoneButton">
              <Button
                ariaLabel="Call phonenumber"
                purpose="primary"
                onClick={() => {
                  togglePhoneDialler(!phoneDiallerOpen);
                }}
                minWidth={40}
                noPadding
              >
                <Row>
                  <img
                    className="phoneImg"
                    alt="phone"
                    src={`${process.env.staticUrl}/admin-icon-phone-black.svg`}
                  />
                </Row>
              </Button>
            </div>
          </div>
          <div className="rightWrapper" />
        </div>

        <style jsx>{styles}</style>
      </header>
    </ThemeContext.Provider>
  );
};

export default Header;

/*

 let profilePictureSource = `${
        process.env.staticUrl
      }/icon-placeholder-profile-picture-square.svg`;
      
    if (user.hasProfilePicture) {
      profilePictureSource = `${
        process.env.generalUploadsUrl
      }/userProfilePicture-${user.id}.png`;
    }
 

  return (
   
          <img
            height={30}
            width={30}
            alt={profilePicAlt}
            src={`${
              process.env.imageUrl
            }?url=${profilePictureSource}&format=jpg&height=120&width=120`}
          />
      )

*/

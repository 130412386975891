import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect
} from "react";
import jwt from "jsonwebtoken";
import cookie from "cookie";
import { useRouter } from "next/router";
import { ResizeObserver } from "resize-observer";
import { gql } from "@apollo/client";
import { permissions } from "./permissions";

export const useBoundingBox = (
  ref: React.MutableRefObject<HTMLElement | null>,
  deps: any[] = []
) => {
  const [boundingBox, setBoundingBox] = useState<ClientRect>();

  const getBoundingBox = useCallback(() => {
    if (ref.current) {
      setBoundingBox(ref.current.getBoundingClientRect());
    }
  }, [ref]);
  const observer = useRef(new ResizeObserver(getBoundingBox));

  useEffect(
    () => {
      if (ref.current) {
        const currentRef = ref.current;
        const currentObserver = observer.current;
        currentObserver.observe(currentRef);

        return () => {
          currentObserver.unobserve(currentRef);
        };
      }
      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref, observer, ...deps]
  );

  return boundingBox;
};

export const useToday = () => {
  const [today, setToday] = useState<Date>(new Date(2019, 0, 1));

  useEffect(() => {
    setToday(new Date());
  }, []);

  return today;
};

export const useNewTimer = (currentDate: Date, interval: number = 1) => {
  const [date, setDate] = useState(currentDate);

  const tick = () => {
    setDate(new Date());
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000 * interval);
    return () => {
      clearInterval(timerID);
    };
  }, [interval]);

  return date;
};

export const usePermissions = (skip = false) => {
  const hasPermission = (requiredPermission: string): boolean => {
    if (!process.browser) {
      return false;
    }
    // get the access token from the cookie
    const cookies = cookie.parse(document.cookie);
    // decode the access token
    if (!cookies[`accessToken.${process.env.clientId}`]) {
      return false;
    }
    const decodedToken: any = jwt.decode(
      cookies[`accessToken.${process.env.clientId}`]
    );
    const userPermissions = decodedToken.data.permissions;

    if (skip) return false;
    // check that the permission exists
    if (!permissions[requiredPermission]) {
      return false;
    }
    // check if the permission exists in the user's permissions
    const userPermissionGroup =
      userPermissions[permissions[requiredPermission].group - 1];
    // eslint-disable-next-line no-bitwise
    if ((userPermissionGroup & permissions[requiredPermission].value) !== 0) {
      return true;
    }
    return false;
  };
  return [hasPermission];
};

export const useWarnOfUnsavedChanges = (unsavedChanges: boolean) => {
  const router = useRouter();
  useEffect(() => {
    const warningText =
      "You have unsaved changes - are you sure you wish to leave this page?";
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (!unsavedChanges) return;
      e.preventDefault();
      e.returnValue = warningText;
    };
    const handleBrowseAway = () => {
      if (!unsavedChanges) return;
      // eslint-disable-next-line no-alert
      if (window.confirm(warningText)) return;
      router.events.emit("routeChangeError");
      // eslint-disable-next-line no-throw-literal
      throw "routeChange aborted.";
    };
    window.addEventListener("beforeunload", handleWindowClose);
    router.events.on("routeChangeStart", handleBrowseAway);
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
      router.events.off("routeChangeStart", handleBrowseAway);
    };
  }, [unsavedChanges, router.events]);
};

// Hook
export const useWhyDidYouUpdate = (name, props) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps: { current: any } = useRef();

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key]
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // eslint-disable-next-line no-console
        console.log("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  }, [name, props]);
};

export const GET_SHOW_SUPPORT = gql`
  query getShowSupport {
    showSupport @client
  }
`;

export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export default {};

import React, { useEffect, useRef, useState } from "react";
import Head from "next/head";
import css from "styled-jsx/css";
import { CookiesProvider, Cookies, withCookies } from "react-cookie";
import smoothscroll from "smoothscroll-polyfill";
import { ApolloProvider } from "@apollo/client";
import Script from "next/script";
import { ParsedUrlQuery } from "querystring";
import { NextPageContext } from "next";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { DndProvider } from "react-dnd-cjs";
import HTML5Backend from "react-dnd-html5-backend-cjs";
import { Auth, getClientSideToken, useAuthenticatedPages } from "../lib/auth";
import stylingConfig from "../styling/config";
import windowEvent from "../lib/subPub";
import { throttle } from "../lib/utils";
import { usePermissions } from "../lib/hooks";
import {
  ThemeContext,
  DeviceIdContext,
  SessionIdContext,
  AuthContext
} from "../lib/contexts";
import Header from "../containers/header";
import NewHeader from "../containers/new/header";
import { useCommonCookies } from "../lib/cookies";
import initApollo from "../lib/initApollo";

if (process.browser) {
  smoothscroll.polyfill();
}

// eslint-disable-next-line prefer-destructuring
const staticUrl = process.env.staticUrl;
const globalStyles = css.global`
  html,
  body {
    margin: 0;
    padding: 0;
    color: ${stylingConfig.colours.darkGreyBlue};
    font-size: 14px;
  }
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
  }
  *:focus {
    outline: 0; /* todo: temporary!! */
  }
  body {
    font-family: WaggelNunito, Helvetica, Arial, sans-serif !important;
    background-color: ${stylingConfig.colours.paleGreyTwo};
    margin: 0;
  }
  button,
  input,
  textarea {
    font-family: WaggelNunito, Helvetica, Arial, sans-serif !important;
  }
  @font-face {
    font-family: WaggelNunito;
    font-display: fallback;
    font-weight: 400;
    src: url(${staticUrl}/Nunito-Regular.woff2) format("woff2"),
      url(${staticUrl}/Nunito-Regular.woff) format("woff");
  }
  @font-face {
    font-family: WaggelNunito;
    font-display: fallback;
    font-weight: 600;
    src: url(${staticUrl}/Nunito-SemiBold.woff2) format("woff2"),
      url(${staticUrl}/Nunito-SemiBold.woff) format("woff");
  }
  @font-face {
    font-family: WaggelNunito;
    font-display: fallback;
    font-weight: 700;
    src: url(${staticUrl}/Nunito-Bold.woff2) format("woff2"),
      url(${staticUrl}/Nunito-Bold.woff) format("woff");
  }
  @font-face {
    font-family: WaggelNunito;
    font-display: fallback;
    font-weight: 800;
    src: url(${staticUrl}/Nunito-ExtraBold.woff2) format("woff2"),
      url(${staticUrl}/Nunito-ExtraBold.woff) format("woff");
  }
  @font-face {
    font-family: Barlow;
    font-display: fallback;
    font-weight: 300;
    src: url(${staticUrl}/Barlow-Light.woff2) format("woff2"),
      url(${staticUrl}/Barlow-Light.woff) format("woff");
  }
  @font-face {
    font-family: WaggelBrother;
    font-display: fallback;
    font-weight: 700;
    src: url(${staticUrl}/Brother-Bold.woff2) format("woff2"),
      url(${staticUrl}/Brother-Bold.woff) format("woff");
  }
  @font-face {
    font-family: WaggelBrother;
    font-display: fallback;
    font-weight: 800;
    src: url(${staticUrl}/Brother-ExtraBold.woff2) format("woff2"),
      url(${staticUrl}/Brother-ExtraBold.woff) format("woff");
  }
  @font-face {
    font-family: WaggelBrother;
    font-display: fallback;
    font-style: italic;
    font-weight: 900;
    src: url(${staticUrl}/Brother-Black-Italic.woff2) format("woff2"),
      url(${staticUrl}/Brother-Black-Italic.woff) format("woff");
  }
  @font-face {
    font-family: WaggelSofia;
    font-display: fallback;
    font-weight: 400;
    src: url(${staticUrl}/Sofia-Regular.woff2) format("woff2"),
      url(${staticUrl}/Sofia-Regular.woff) format("woff");
  }
  @font-face {
    font-family: WaggelSofia;
    font-display: fallback;
    font-weight: 700;
    src: url(${staticUrl}/Sofia-Bold.woff2) format("woff2"),
      url(${staticUrl}/Sofia-Bold.woff) format("woff");
  }

  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 17px;
  }
  h3 {
    font-size: 14px;
    margin: 0;
  }
  p {
    font-size: 14px;
  }
  p {
    color: ${stylingConfig.colours.steel};
    margin: 0;
  }
  hr {
    border: 0.5px inset ${stylingConfig.colours.paleGreyThree};
  }
  a {
    text-decoration: none;
  }
`;

const styles = css`
  .app-wrapper {
    display: flex;
    align-items: stretch;
    /* overflow: hidden; */
    /* height: calc(100vh - 68px); */
    margin-top: 68px;
    width: 100vw;
  }
  .newStyle .app-wrapper {
    margin-top: 60px;
  }
  .app-wrapper.analytics {
    height: 100vh;
    margin-top: 0;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
  .children-wrapper {
    position: relative;
    flex-grow: 1;
    /* overflow: auto;
    -webkit-overflow-scrolling: touch; */
  }
  .children-wrapper.showSupport {
    margin-right: 100px;
  }
  .app-wrapper.analytics .children-wrapper {
    margin-right: 0;
  }
  .sidebar {
    position: fixed;
    right: 0;
    z-index: 100;
    /* flex-shrink: 0; */
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .children-wrapper.analytics {
    background-color: ${stylingConfig.colours.bluishBlack};
    color: ${stylingConfig.colours.koda};
  }
  .children-wrapper.analytics :global(p) {
    color: ${stylingConfig.colours.koda};
  }

  .headerPortal {
    padding: 0 14px;
  }
  .headerPortal.newStyle {
    width: 220px;
    background: ${stylingConfig.colours.penny};
  }
`;

export interface PageProps {
  query: ParsedUrlQuery;
  auth: Auth;
}

export type PageComponent<StaticProps = {}> = {
  (props: StaticProps & PageProps): JSX.Element;
  getInitialProps?(context: NextPageContext): any;
  redirectToLoginIfNotAuthenticated?: boolean;
  redirectBackAfterLogin?: boolean;
  title: string;
  description?: string;
  header?: boolean;
  newStyle?: boolean;
  theme?: string;
};

function MyApp({
  Component,
  pageProps,
  cookies
}: AppProps & {
  Component: PageComponent;
  cookies: Cookies;
}) {
  const router = useRouter();

  const { deviceId, sessionId } = useCommonCookies(cookies);

  const [auth, setAuth] = useState(
    getClientSideToken(cookies.get(`accessToken.${process.env.clientId}`))
  );

  const client = initApollo(
    pageProps.apolloState,
    undefined,
    deviceId,
    sessionId
  );

  useAuthenticatedPages(
    auth,
    router.asPath,
    Component.redirectToLoginIfNotAuthenticated,
    Component.redirectBackAfterLogin
  );
  const childrenWrapperRef = useRef();

  useEffect(() => {
    const { current }: { current: HTMLElement | undefined } =
      childrenWrapperRef;
    if (current) {
      current.addEventListener(
        "scroll",
        throttle(windowEvent.scroll.publish, 50)
      );
      current.addEventListener(
        "resize",
        throttle(windowEvent.resize.publish, 50)
      );
      document.addEventListener(
        "click",
        throttle(windowEvent.click.publish, 50)
      );

      return () => {
        current.removeEventListener(
          "scroll",
          throttle(windowEvent.scroll.publish, 50)
        );
        current.removeEventListener(
          "resize",
          throttle(windowEvent.resize.publish, 50)
        );
        document.removeEventListener(
          "click",
          throttle(windowEvent.click.publish, 50)
        );
      };
    }
    return () => {};
  });

  const [hasPermissions] = usePermissions(!auth.hasAccessToken);

  const { theme = "default", header = true, newStyle = false } = Component;

  let showHeader = true;
  if (theme === "analytics" || !header) {
    showHeader = false;
  }

  /* eslint-disable max-len, react/no-danger, jsx-a11y/alt-text */
  return (
    <DndProvider backend={HTML5Backend}>
      <AuthContext.Provider value={[auth, setAuth]}>
        <ThemeContext.Provider value={theme}>
          <DeviceIdContext.Provider value={deviceId}>
            <SessionIdContext.Provider value={sessionId}>
              <CookiesProvider>
                <ApolloProvider client={client}>
                  <div className={newStyle ? "newStyle" : ""}>
                    <Head>
                      <title>{Component.title}</title>
                      <meta charSet="utf-8" />
                      <meta
                        name="Description"
                        content={Component.description ?? Component.title}
                      />
                      <meta
                        name="viewport"
                        content="initial-scale=1.0, width=device-width"
                      />
                      <meta name="theme-color" content="#FFFFFF" />
                      <link
                        rel="icon"
                        href={`${process.env.staticUrl}/favicon.ico`}
                      />
                    </Head>
                    <Script src="https://media.twiliocdn.com/sdk/js/client/v1.7/twilio.min.js" />
                    {showHeader && (
                      <div className="header">
                        {newStyle ? <NewHeader /> : <Header />}
                      </div>
                    )}
                    <div className={`app-wrapper ${theme}`}>
                      <div
                        className={`children-wrapper ${theme}`}
                        ref={childrenWrapperRef}
                      >
                        <Component
                          {...pageProps}
                          {...{
                            auth,
                            sessionId,
                            deviceId,
                            query: router.query
                          }}
                        />
                      </div>
                    </div>
                    <style jsx>{globalStyles}</style>
                    <style jsx>{styles}</style>
                  </div>
                </ApolloProvider>
              </CookiesProvider>
            </SessionIdContext.Provider>
          </DeviceIdContext.Provider>
        </ThemeContext.Provider>
      </AuthContext.Provider>
    </DndProvider>
  );
  /* eslint-enable max-len, react/no-danger, jsx-a11y/alt-text */
}

export default withCookies(MyApp as any);
